@import 'styles/base/functions';
@import 'styles/base/mixins';
@import 'styles/base/variables';

.headlineSpan {
  display: block;
}

.headlineH1 {
  font-size: var(--font-size-5);
  line-height: var(--line-height-5);
  text-transform: var(--h1-title-text-transform);
  font-weight: var(--h1-title-font-weight);
  letter-spacing: calc-letter-spacing(5);

  .headlineSpan {
    text-transform: var(--h1-subtitle-text-transform);
    font-weight: var(--h1-subtitle-font-weight);
  }

  @include screen-sm {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
  }

  @include screen-md {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  @include screen-lg {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
  }
}

.headlineH2 {
  font-size: var(--font-size-6);
  line-height: var(--line-height-6);
  text-transform: var(--h2-title-text-transform);
  font-weight: var(--h2-title-font-weight);
  letter-spacing: calc-letter-spacing(20);

  .headlineSpan {
    text-transform: var(--h2-subtitle-text-transform);
    font-weight: var(--h2-subtitle-font-weight);
  }

  @include screen-sm {
    font-size: var(--font-size-5);
    line-height: var(--line-height-5);
  }

  @include screen-md {
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
  }

  @include screen-lg {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
  }
}

.headlineH3 {
  font-size: var(--font-size-7);
  line-height: var(--line-height-7);
  text-transform: var(--h3-title-text-transform);
  font-weight: var(--h3-title-font-weight);
  letter-spacing: calc-letter-spacing(20);

  .headlineSpan {
    text-transform: var(--h3-subtitle-text-transform);
    font-weight: var(--h3-subtitle-font-weight);
  }

  @include screen-sm {
    font-size: var(--font-size-6);
    line-height: var(--line-height-6);
  }

  @include screen-md {
    font-size: var(--font-size-5);
    line-height: var(--line-height-5);
  }

  @include screen-lg {
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
  }
}

.headlineH4 {
  font-size: var(--font-size-8);
  line-height: var(--line-height-8);
  text-transform: var(--h4-title-text-transform);
  font-weight: var(--h4-title-font-weight);
  letter-spacing: calc-letter-spacing(20);

  .headlineSpan {
    text-transform: var(--h4-subtitle-text-transform);
    font-weight: var(--h4-subtitle-font-weight);
  }

  @include screen-sm {
    font-size: var(--font-size-7);
    line-height: var(--line-height-7);
  }

  @include screen-md {
    font-size: var(--font-size-6);
    line-height: var(--line-height-6);
  }

  @include screen-lg {
    font-size: var(--font-size-5);
    line-height: var(--line-height-5);
  }
}

.headlineH5 {
  font-size: var(--font-size-9);
  line-height: var(--line-height-9);
  text-transform: var(--h5-title-text-transform);
  font-weight: var(--h5-title-font-weight);

  .headlineSpan {
    text-transform: var(--h5-subtitle-text-transform);
    font-weight: var(--h5-subtitle-font-weight);
  }

  @include screen-sm {
    font-size: var(--font-size-8);
    line-height: var(--line-height-8);
  }

  @include screen-md {
    font-size: var(--font-size-7);
    line-height: var(--line-height-7);
  }

  @include screen-lg {
    font-size: var(--font-size-7);
    line-height: var(--line-height-7);
  }
}

.headlineH6 {
  font-size: var(--font-size-10);
  line-height: var(--line-height-10);
  text-transform: var(--h6-title-text-transform);
  font-weight: var(--h6-title-font-weight);

  .headlineSpan {
    text-transform: var(--h6-subtitle-text-transform);
    font-weight: var(--h6-subtitle-font-weight);
  }

  @include screen-sm {
    font-size: var(--font-size-9);
    line-height: var(--line-height-9);
  }

  @include screen-md {
    font-size: var(--font-size-9);
    line-height: var(--line-height-9);
  }

  @include screen-lg {
    font-size: var(--font-size-9);
    line-height: var(--line-height-9);
  }
}

.headlineSubtitle {
  font-size: var(--font-size-10);
  line-height: var(--line-height-10);
  text-transform: var(--h4-title-text-transform);
  font-weight: var(--h4-title-font-weight);
  letter-spacing: calc-letter-spacing(20);
}
