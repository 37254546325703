@import '../../../../styles/base/variables';
@import '../../../../styles/base/mixins';

.gddsForm {
  margin: $spacing-gdds-section-mobile-large;

  @include screen-sm {
    margin: $spacing-gdds-section-small;
    margin-left: auto;
    margin-right: auto;
  }
}

.formBorder {
  border-bottom: 1px solid $gb_black;
  margin-bottom: 3.75rem;
}

.border {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;

  @include screen-sm {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}

.gddsGridContainer {
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 0.75rem;
  }

  .copyText {
    margin-bottom: 2.5rem;

    > p {
      font-size: 1rem;
      margin-bottom: 0rem;
    }
  }

  form,
  > div {
    flex: 1;
    flex-direction: row;
  }

  form {
    --gap: 1.5rem;
    --half: calc(calc(100% - var(--gap)) / 2);

    .formFieldsGroup > div:last-child {
      margin-bottom: var(--gap);
    }
    .formFieldset,
    .formFieldsGroup {
      gap: var(--gap);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      :global(.full) {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 100%;
      }

      :global(.half) {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 100%;

        @include screen-md {
          flex-basis: var(--half);
        }
      }
    }

    .formFieldset {
      position: relative;
      padding: 1.5rem;
      margin-bottom: 1.5rem;
      background-color: $gb_white;

      @include screen-md {
        padding: 2rem;
      }

      &.light-grey {
        background-color: $gb_gdds_cl03;
      }

      &.white {
        background-color: $gb_white;
        padding-left: 0;
        padding-right: 0;
      }

      &.grey {
        background-color: $color-black-06;
      }

      &.porcelain {
        background-color: $porcelain;
      }

      legend {
        all: unset;
        font-size: 1.125rem;
        line-height: 1.5rem;
        font-weight: 700;
        color: $gb_black;
        float: left;
        display: flex;
        box-sizing: border-box;
        padding-inline-start: 0;
        padding-inline-end: 0;
        width: 100%;
      }
    }

    .spacer {
      display: none;

      @include screen-md {
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: var(--half);
      }
    }
  }

  :global(.hide) {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0;
  }

  :global(.twyford) & {
    h2 {
      font-weight: $font-weight-bold;

      span {
        font-weight: $font-weight-bold;
      }

      span[class*='headline_subtitle'] {
        font-weight: $font-weight-light;

        span {
          font-weight: $font-weight-light;
        }
      }
    }
  }
}

#{&}#{&} .nordicsForm {
  :global(.dependency-dropdown) {
    margin-bottom: var(--gap);

    @include screen-sm {
      margin-bottom: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  :global(.dependency-dropdown + .dependency-dropdown + .dependency-dropdown) {
    margin-top: 1rem;
  }

  form {
    .rowElement > .gddsField {
      ul {
        z-index: 99;

        li:last-of-type {
          padding-bottom: inherit;
        }
      }

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }

    input[type='date'] {
      padding: 0 0.5rem;
    }

    input {
      padding: 0 0.5rem;
    }
  }

  form input:hover,
  form input:focus,
  form textarea:hover,
  form textarea:focus,
  form select:hover,
  form select:focus {
    box-shadow: none;
  }

  @include below-screen-sm {
    form select {
      line-height: 1;
    }
    :global(.select) > div > span {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  input:read-only,
  input:disabled {
    cursor: initial;
  }

  :global(.form-item) {
    margin: 0;
  }

  :global(.inline-text p) {
    font-size: 1rem;
    margin-bottom: 0;
  }

  .copyText p {
    min-width: 100%;
  }
}

#{&}#{&}#{&} .koloForm {
  form {
    :global(fieldset) {
      // override global styles again
      input[type='text'],
      input[type='email'],
      input[type='date'],
      input[type='number'],
      input[type='password'],
      div[type='dropdown'],
      textarea,
      select {
        border: 1px solid $gb_gdds_cl10;

        &:hover {
          border: 1px solid $gb_grey_700;
          background-color: $gb_grey_025;
        }

        &:active {
          background-color: $gb_grey_060;
        }

        &:focus {
          border: 1px solid var(--gb-brand-primary-light);
        }
      }
    }
  }

  .copyText p {
    min-width: 100%;
  }
}
